import { useMutation } from "@apollo/client";
import { useNavigation, useNotification } from "@pankod/refine-core";
import { Create,  TextInput, Select } from "@pankod/refine-mantine";
import { CREATE_TRANSACTION } from "graphql/transactions/create-transaction.mutation";
import { toNumber } from "lodash";
import { Currency } from "pages/exchange-rates/list";
import { useState } from "react";

enum PaymentType {
  TRANSFER,
  DEPOSIT,
  WITHDRAWAL,
  INTERNET_TOPUP,
}

enum ProviderType {
  ZAAD,
  EDAHAB,
  SOLTELCO,
  EVC,
}

interface TransferCreateInput {
  phoneNumberFrom: string;
  phoneNumberTo: string;

}

interface WithdrawalRequestThirdPartyProviderInput {
  provider: string;
  accountNumber: string;
  acccountName: string;
}

interface DepositRequestThirdPartyProviderInput
  extends Pick<WithdrawalRequestThirdPartyProviderInput, "provider"> {
  senderAccountNumber: string;
}

interface WithdrawalCreateInput
 {
  thirdPartyProviderDetails: WithdrawalRequestThirdPartyProviderInput;
}

interface DepositCreateInput
 {
  thirdPartyProviderDetails: DepositRequestThirdPartyProviderInput;
}

interface CreateTransactionInput {
  paymentType: string;
  phoneNumber: string;
  amount: number;
  currencyCode: string;
  transferInput: TransferCreateInput;
  withdrawalInput: WithdrawalCreateInput;
  depositInput: DepositCreateInput;
}

const currencyTypes: string[] = Object.keys(Currency).filter((key) =>
  isNaN(Number(key))
);

const paymentTypes: string[] = Object.keys(PaymentType).filter((key) =>
  isNaN(Number(key))
);

const providerTypes: string[] = Object.keys(ProviderType).filter((key) =>
  isNaN(Number(key))
);

export const TransactionCreate: React.FC = () => {
  const { push } = useNavigation();
  const { open } = useNotification();
  const [createTransaction, { loading }] = useMutation(CREATE_TRANSACTION);
  const [inputs, setInputs] = useState<CreateTransactionInput>({
    paymentType: "TRANSFER",
    phoneNumber: "",
    amount: 0,
    currencyCode: "USD",
    transferInput: {
      phoneNumberFrom: "",
      phoneNumberTo: "",
    },
    withdrawalInput: {
      thirdPartyProviderDetails: {
        provider: "ZAAD",
        accountNumber: "",
        acccountName: "",
      },
    },
    depositInput: {
      thirdPartyProviderDetails: {
        provider: "ZAAD",
        senderAccountNumber: "",
      },
    },
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await createTransaction({
        variables: {
          input: {
            ...inputs,
            amount:toNumber(inputs.amount),
            transferInput:{
              ...inputs.transferInput,
              amount:toNumber(inputs.amount),
              currencyCode:inputs.currencyCode
            },
            depositInput:{
              ...inputs.depositInput,
              amount:toNumber(inputs.amount),
              currencyCode:inputs.currencyCode
            },
            withdrawalInput:{
              ...inputs.withdrawalInput,
              amount:toNumber(inputs.amount),
              currencyCode:inputs.currencyCode
            },
           
          },
        },
      });
      open?.({
        type: "success",
        message: "Successfully created transaction ",
      });
      push("/transactions");
    } catch (e: any) {
      open?.({
        type: "error",
        message: e.message,
      });
    }
  };

  return (
    <Create isLoading={loading} saveButtonProps={{ onClick: handleSubmit }}>
      <Select
        mt={8}
        zIndex={1000}
        label="PaymentType"
        placeholder="Select PaymentType"
        value={inputs.paymentType}
        onChange={(e: string) => {
          setInputs({
            ...inputs,
            paymentType: e,
          });
        }}
        data={paymentTypes}
      />

      {/* For Transfer */}
      {inputs.paymentType === "TRANSFER" && (
        <>
          <TextInput
            mt={8}
            placeholder="PhoneNumberFrom"
            label="PhoneNumberFrom"
            value={inputs.transferInput.phoneNumberFrom}
            onChange={(e) =>
              setInputs({
                ...inputs,
                transferInput: {
                  ...inputs.transferInput,
                  phoneNumberFrom: e.target.value,
                },
              })
            }
          />
          <TextInput
            mt={8}
            placeholder="PhoneNumberTo"
            label="PhoneNumberTo"
            value={inputs.transferInput.phoneNumberTo}
            onChange={(e) =>
              setInputs({
                ...inputs,
                transferInput: {
                  ...inputs.transferInput,
                  phoneNumberTo: e.target.value,
                },
              })
            }
          />
        </>
      )}

      {/* For Deposit & Withdrawal */}
      {inputs.paymentType === "WITHDRAWAL" ||
      inputs.paymentType === "DEPOSIT" ? (
        <>
          <TextInput
            mt={8}
            placeholder="Phone Number"
            label="Phone Number"
            value={inputs.phoneNumber}
            onChange={(e) =>
              setInputs({
                ...inputs,
                phoneNumber: e.target.value,
              })
            }
          />
          <Select
            mt={8}
            label="Provider"
            placeholder="Select provider"
            value={inputs.withdrawalInput.thirdPartyProviderDetails.provider} // Since they take same value we can choose one
            onChange={(e: string) =>
              setInputs({
                ...inputs,
                withdrawalInput: {
                  ...inputs.withdrawalInput,
                  thirdPartyProviderDetails: {
                    ...inputs.withdrawalInput.thirdPartyProviderDetails,
                    provider: e,
                  },
                },
                depositInput: {
                  ...inputs.depositInput,
                  thirdPartyProviderDetails: {
                    ...inputs.depositInput.thirdPartyProviderDetails,
                    provider: e,
                  },
                },
              })
            }
            data={providerTypes}
          />
        </>
      ) : null}

      {/* For Withdrawal */}
      {inputs.paymentType === "WITHDRAWAL" && (
        <>
          <TextInput
            mt={8}
            placeholder="Account Number"
            label="Account Number"
            value={
              inputs.withdrawalInput.thirdPartyProviderDetails.accountNumber
            }
            onChange={(e) =>
              setInputs({
                ...inputs,
                withdrawalInput: {
                  ...inputs.withdrawalInput,
                  thirdPartyProviderDetails: {
                    ...inputs.withdrawalInput.thirdPartyProviderDetails,
                    accountNumber: e.target.value,
                  },
                },
              })
            }
          />
          <TextInput
            mt={8}
            placeholder="Account Name"
            label="Account Name"
            value={
              inputs.withdrawalInput.thirdPartyProviderDetails.acccountName
            }
            onChange={(e) =>
              setInputs({
                ...inputs,
                withdrawalInput: {
                  ...inputs.withdrawalInput,
                  thirdPartyProviderDetails: {
                    ...inputs.withdrawalInput.thirdPartyProviderDetails,
                    acccountName: e.target.value,
                  },
                },
              })
            }
          />
        </>
      )}

      {/* For Deposit */}
      {inputs.paymentType === "DEPOSIT" && (
        <>
          <TextInput
            mt={8}
            placeholder="Sender Account Number"
            label="Sender Account Number"
            value={
              inputs.depositInput.thirdPartyProviderDetails.senderAccountNumber
            }
            onChange={(e) =>
              setInputs({
                ...inputs,
                depositInput: {
                  ...inputs.depositInput,
                  thirdPartyProviderDetails: {
                    ...inputs.depositInput.thirdPartyProviderDetails,
                    senderAccountNumber: e.target.value,
                  },
                },
              })
            }
          />
        </>
      )}

      <TextInput
        mt={8}
        placeholder="Amount"
        label="Amount"
        value={inputs.amount}
        onChange={(e) =>
          setInputs({
            ...inputs,
            amount:e.target.value as any
          })
        }
      />
      <Select
        mt={8}
        label="Currency"
        placeholder="Select Currency Code"
        value={inputs.currencyCode}
        onChange={(e: string) =>
          setInputs({
            ...inputs,
            currencyCode:e
          })
        }
        data={currencyTypes}
      />
    </Create>
  );
};
